<template>
  <div>

    <section>
      <Hero>
        <div>
          <div class="container">
            <HeadText :titletext="this.switchTitle" :subtext="''"></HeadText>
          </div>
          <div class="container bg-light pt-3 px-3" v-if="!hasToken()">
            <b-row>
              <b-col md="12" class="text-left">
                <p>
                  Sie sind nicht angemeldet. Bitte melden Sie sich <router-link :to="{name: 'Login', params: {client}}">hier</router-link> an.
                </p>
              </b-col>
            </b-row>  
          </div>
          <div class="container bg-light pt-3 px-3" v-if="this.handelausgesetzt && hasToken()">
            <b-row>
              <b-col md="12" class="text-left">
                <div class="alert alert-danger">
                  <p>
                    Der Fonds ist derzeit vom Handel ausgesetzt, bitte setzen Sie sich mit uns in Verbindung.
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="container bg-light pt-3 px-3" v-if="hasToken() && !this.handelausgesetzt">
            <b-row>
              <b-col class="text-left">
                <form-wizard @on-complete="onComplete"
                             @on-tab-changed="scrollToTop"
                             ref="wizard"
                             shape="circle" error-color="#dc3545"
                             validate-on-back
                             :start-index.sync="activeTabIndex"
                             :hide-buttons="true">

                  <tab-content title="Schritt 1" icon="ti-info">
                    <first-step ref="firstStep" @nextStep="nextStep()"></first-step>
                  </tab-content>

                  <tab-content title="Schritt 2" icon="ti-calculator" scope="props">
                    <second-step ref="secondStep" @prevStep="prevStep()" @nextStep="nextStep()"></second-step>
                  </tab-content>

                  <tab-content title="Schritt 3" icon="ti-address-card">
                    <third-step ref="thirdStep" @prevStep="prevStep()" @nextStep="nextStep()"></third-step>
                  </tab-content>

                  <tab-content title="Schritt 4" icon="ti-check">
                    <fourth-step ref="fourthStep" @prevStep="prevStep()"></fourth-step>
                  </tab-content>

                </form-wizard>
              </b-col>
            </b-row>
          </div>

        </div>
      </Hero>
    </section>  
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import Vue from "vue";
import VueFormWizard from "vue-form-wizard";
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import FirstStep from "@/components/auftragswizard/FirstStep.vue";
import SecondStep from "@/components/auftragswizard/SecondStep.vue";
import ThirdStep from "@/components/auftragswizard/ThirdStep.vue";
import FourthStep from "@/components/auftragswizard/FourthStep.vue";
import router from "../router";
import {mapActions} from "vuex";
import axios from "axios";

Vue.use(VueFormWizard);

export default {
  name: "AuftragsVerwaltung",
  components: {Hero, HeadText, FirstStep, SecondStep, ThirdStep, FourthStep},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
    }
  },
  data() {
    return {
      activeTabIndex: 0,
      handelausgesetzt: false,
    };
  },
  methods: {
    ...mapActions('auftragswizard', ['emptySecondStepData', 'emptyThirdStepData', 'resetStatusErrorCodes']),

    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    loadTrancheData() {
      let token = this.$store.getters['auth/getToken']
      if (token === '' || token === undefined) {
        throw new Error('Missing Token!')
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get('tid');

      const uri = this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_TRANCHE_HANDELSDATEN.replace('{id}', id) + '?subsite=' + this.$store.getters["getSubsite"];
      axios.get(
          uri,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
              'XClacksOverhead': 'GNU Terry Pratchett',
              'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
            },
            crossdomain: true,
          }
      )
      .then(response => {
        this.handelausgesetzt  = response.data.handelausgesetzt ;
      })
      .catch(error => {
        console.log(error);
      })
    },
    onComplete() {
      router.push({name: 'AuftragsVerwaltungThanks', params: {client: this.$store.getters['getSubsite']}});
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    prevStep() {
      this.$refs.wizard.prevTab()
    },
    nextStep() {
      this.$refs.wizard.nextTab()
    }
  },
  computed: {
    switchTitle() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get('auftragType');
      const auftragNr = urlParams.get('auftragNr');

      if(auftragNr && type === 'KAUF') {
        return 'Kaufauftrag ändern';
      } else if(!auftragNr && type === 'KAUF') {
        return 'Ihr Kaufauftrag';
      } else if(auftragNr && type === 'VERKAUF') {
        return 'Verkaufsauftrag ändern';
      } else if(!auftragNr && type === 'VERKAUF') {
        return 'Ihr Verkaufsauftrag';
      } else {
        return 'Auftragsverwaltung';
      }
    }
  },
  created() {
    this.$store.dispatch('auftragswizard/emptySecondStepData');
    this.$store.dispatch('auftragswizard/emptyThirdStepData');
    this.$store.dispatch('auftragswizard/resetStatusErrorCodes');
    this.loadTrancheData();
  }
}
</script>

<style lang="scss">

</style>