<template>
  <div>

    <AddressModal v-bind:addressData="addressData"></AddressModal>

    <div class="row mt-4">
      <div class="col-md-7 mb-md-4 mb-sm-5">
        <form ref="topAnchor"></form>
        <h3 class="mt-2">Auftraggeber/Kunde</h3>
        <p>
          Bitte ergänzen Sie die zur Auftragserstellung fehlenden Daten. Die Angaben werden erst bei Erstellung des Auftrags gespeichert.
        </p>
        <b-form id="dataForm2"
              ref="address"
              @change="saveThirdStepData"
        >
          <div class="form-row" v-if="this.isCompany">
            <div class="f    s
    Code
    Activity

    Project Information

Quality Gate Status
Some Quality Gate conditions on New Code were ignored because of the small number of New Lines
Passed
All conditions passed.
Measures
0
New Bugs
ReliabilityA
0
New Vulnerabilities
SecurityA
0
orm-group col-md-12 required">
              <label class="small">Firma</label>
              <b-input
                  id="companyName"
                  type="text"
                  v-model.trim="$v.form.companyName.$model"
                  :state="validateState('companyName')"
                  class="form-control form-control-sm form-control"
                  area-descibed-by="companyNameInputField-live-feedback"
              />
              <b-form-invalid-feedback id="companyNameInputField-live-feedback">
                <span v-if="!$v.form.companyName.$model">Bitte geben Sie Ihren Firmennamen an.</span>
                <span v-if="!$v.form.companyName.maxLength">Ihr Firmenname darf maximal 100 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>

          <div v-else>
            <div class="form-row">
              <div class="form-group col-md-6 required">
                <label class="small">Anrede</label>
                <b-form-select
                    id="salutationInputField"
                    v-model.trim="$v.form.salutation.$model"
                    :state="validateState('salutation')"
                    class="form-control form-control-sm custom-select custom-select-sm"
                    area-descibed-by="salutationInputField-live-feedback"
                >
                  <option disabled="disabled" value="">Anrede</option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </b-form-select>
                <b-form-invalid-feedback id="salutationInputField-live-feedback">
                  <span v-if="!$v.form.salutation.$model">Bitte geben Sie Ihre Anrede an.</span>
                </b-form-invalid-feedback>
              </div>
              <div class="form-group col-md-6">
                <label class="small">Titel</label>
                <b-input
                    id="titel"
                    type="text"
                    v-model="$v.form.titel.$model"
                    class="form-control form-control-sm form-control"
                    aria-describedby="titel-live-feedback"
                    :state="validateState('titel')"
                />
                <b-form-invalid-feedback id="titel-live-feedback">
                  <span v-if="!$v.form.titel.maxLength">Ihr Titel darf maximal 10 Zeichen betragen.</span>
                </b-form-invalid-feedback>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12 required">
                <label class="small">Vorname</label>
                <b-input
                    id="vorname"
                    type="text"
                    v-model.trim="$v.form.vorname.$model"
                    :state="validateState('vorname')"
                    class="form-control form-control-sm form-control"
                    area-descibed-by="vornameInputField-live-feedback"
                />
                <b-form-invalid-feedback id="vornameInputField-live-feedback">
                  <span v-if="!$v.form.vorname.$model">Bitte geben Sie Ihren Vornamen an.</span>
                  <span v-if="!$v.form.vorname.maxLength">Ihr Vorname darf maximal 50 Zeichen betragen.</span>
                </b-form-invalid-feedback>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12 required">
                <label class="small">Nachname</label>
                <b-input
                    id="nachname"
                    type="text"
                    v-model.trim="$v.form.nachname.$model"
                    :state="validateState('nachname')"
                    class="form-control form-control-sm form-control"
                    area-descibed-by="nachnameInputField-live-feedback"
                />
                <b-form-invalid-feedback id="nachnameInputField-live-feedback">
                  <span v-if="!$v.form.nachname.$model">Bitte geben Sie Ihren Nachnamen an.</span>
                  <span v-if="!$v.form.nachname.maxLength">Ihr Vorname darf maximal 100 Zeichen betragen.</span>
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-9 required">
              <label class="small">Strasse</label>
              <b-input
                  id="strasse"
                  type="text"
                  v-model.trim="$v.form.strasse.$model"
                  :state="validateState('strasse')"
                  class="form-control form-control-sm form-control"
                  area-descibed-by="strasseInputField-live-feedback"
              />
              <b-form-invalid-feedback id="strasseInputField-live-feedback">
                <span v-if="!$v.form.strasse.$model">Bitte geben Sie Ihre Strasse an.</span>
                <span v-if="!$v.form.strasse.maxLength">Ihre Strasse darf maximal 200 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group col-md-3 required">
              <label class="small">Hausnummer</label>
              <b-input
                  id="hausnummer"
                  type="text"
                  v-model.trim="$v.form.hausnummer.$model"
                  :state="validateState('hausnummer')"
                  class="form-control form-control-sm form-control"
                  area-descibed-by="hausnummerInputField-live-feedback"
              />
              <b-form-invalid-feedback id="hausnummerInputField-live-feedback">
                <span v-if="!$v.form.hausnummer.$model">Bitte geben Sie Ihre Hausnummer an.</span>
                <span v-if="!$v.form.hausnummer.maxLength">Ihre Hausnummer darf maximal 10 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="small">Zusatz</label>
              <b-input
                  id="zusatz"
                  type="text"
                  v-model="$v.form.zusatz.$model"
                  class="form-control form-control-sm form-control"
                  aria-describedby="zusatz-live-feedback"
                  :state="validateState('zusatz')"
              />
              <b-form-invalid-feedback id="zusatz-live-feedback">
                <span v-if="!$v.form.zusatz.maxLength">Ihr Zusatz darf maximal 200 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-3 required">
              <label class="small">PLZ</label>
              <b-input
                  id="plz"
                  type="text"
                  v-model="$v.form.plz.$model"
                  :state="validateState('plz')"
                  class="form-control form-control-sm form-control"
                  area-descibed-by="plzInputField-live-feedback"
              />
              <b-form-invalid-feedback id="plzInputField-live-feedback">
                <span v-if="!$v.form.plz.$model">Bitte geben Sie Ihre Postleitzahl an.</span>
                <span v-if="!$v.form.plz.maxLength">Ihre Postleitzahl darf maximal 100 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group col-md-9 required">
              <label class="small">Ort</label>
              <b-input
                  id="ort"
                  type="text"
                  v-model.trim="$v.form.ort.$model"
                  :state="validateState('ort')"
                  class="form-control form-control-sm form-control"
                  area-descibed-by="ortInputField-live-feedback"
              />
              <b-form-invalid-feedback id="ortInputField-live-feedback">
                <span v-if="!$v.form.ort.$model">Bitte geben Sie Ihren Ort an.</span>
                <span v-if="!$v.form.ort.maxLength">Ihr Ort darf maximal 200 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row mb-4">
            <div class="form-group col-md-12 required">
              <label class="small">Land</label>
              <b-form-select
                  id="countryInputField"
                  size="sm"
                  v-model.trim="$v.form.country.$model"
                  :state="validateState('country')"
                  class="form-control form-control-sm custom-select custom-select-sm"
                  aria-describedby="countryInputField-live-feedback"
                  :options="countries">
                <template #first>
                  <b-form-select-option :value="null" disabled>-- Land --</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback id="countryInputField-live-feedback">
                <span v-if="!$v.form.country.$model">Bitte geben Sie Ihr Land an.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div>
            <div class="small mb-3">
              Bitte geben Sie mindestens eine vollständige Telefonnummer oder vollständige Mobilnummer an:
            </div>
            <div class="small mb-2">Telefon</div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <b-input
                    id="phoneCountryPreselection"
                    type="text"
                    v-model.trim="$v.form.telefon_laenderkennung.$model"
                    v-on:keypress="phoneNrCheck"
                    class="form-control form-control-sm form-control"
                    aria-describedby="phoneCountryPreselection-live-feedback"
                    :state="validateState('telefon_laenderkennung')"
                />
                <label class="small">Ländervorwahl</label>
                <b-form-invalid-feedback id="phoneCountryPreselection-live-feedback">
                  <span v-if="!$v.form.telefon_laenderkennung.maxLength">Ihre Ländervorwahl darf maximal 10 Zeichen betragen.</span>
                </b-form-invalid-feedback>
              </div>
              <div class="form-group col-md-3">
                <b-input
                    id="phoneCityPreselection"
                    type="text"
                    v-model.trim="$v.form.telefon_vorwahl.$model"
                    v-on:keypress="phoneNrCheck"
                    class="form-control form-control-sm form-control"
                    :state="validateState('telefon_vorwahl')"
                    aria-describedby="phoneCityPreselection-live-feedback"
                />
                <label class="small">Ortsvorwahl</label>
                <b-form-invalid-feedback id="phoneCityPreselection-live-feedback">
                  <span v-if="!$v.form.telefon_vorwahl.maxLength">Ihre Ortsvorwahl darf maximal 10 Zeichen betragen.</span>
                </b-form-invalid-feedback>
              </div>
              <div class="form-group col-md-6 required">
                <b-input
                    id="phoneNumber"
                    type="text"
                    v-model.trim="$v.form.telefon_rufnummer.$model"
                    :state="validateState('telefon_rufnummer')"
                    v-on:keypress="phoneNrCheck"
                    aria-describedby="phoneNumberInputField-live-feedback"
                    class="form-control form-control-sm form-control"
                />
                <label class="small">Rufnummer</label>
                <b-form-invalid-feedback id="phoneNumberInputField-live-feedback">
                  <span v-if="!$v.form.telefon_rufnummer.$model">Bitte geben Sie entweder Ihre Telefon- oder Mobiltelefon-Rufnummer an.</span>
                  <span v-if="!$v.form.telefon_rufnummer.maxLength">Ihre Rufnummer darf maximal 50 Zeichen betragen.</span>
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>
          <div class="small mb-4">und/oder</div>
          <div class="small mb-2">Mobiltelefon</div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <b-input
                  id="mobileCountryPreselection"
                  type="text"
                  v-model.trim="$v.form.mobil_laenderkennung.$model"
                  v-on:keypress="phoneNrCheck"
                  class="form-control form-control-sm form-control"
                  :state="validateState('mobil_laenderkennung')"
                  aria-describedby="mobileCountryPreselection-live-feedback"
              />
              <label class="small">Ländervorwahl</label>
              <b-form-invalid-feedback id="mobileCountryPreselection-live-feedback">
                <span v-if="!$v.form.mobil_laenderkennung.maxLength">Ihre Ländervorwahl darf maximal 10 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group col-md-3">
              <b-input
                  id="mobileCityPreselection"
                  type="text"
                  v-model.trim="$v.form.mobil_vorwahl.$model"
                  v-on:keypress="phoneNrCheck"
                  aria-describedby="mobileCityPreselection-live-feedback"
                  :state="validateState('mobil_vorwahl')"
                  class="form-control form-control-sm form-control"
              />
              <b-form-invalid-feedback id="mobileCityPreselection-live-feedback">
                <span v-if="!$v.form.mobil_vorwahl.maxLength">Ihre Ortsvorwahl darf maximal 10 Zeichen betragen.</span>
              </b-form-invalid-feedback>
              <label class="small">Ortsvorwahl</label>
            </div>
            <div class="form-group col-md-6 required">
              <b-input
                  id="mobileNumber"
                  type="text"
                  v-model.trim="$v.form.mobil_rufnummer.$model"
                  :state="validateState('mobil_rufnummer')"
                  v-on:keypress="phoneNrCheck"
                  aria-describedby="mobileNumberInputField-live-feedback"
                  class="form-control form-control-sm form-control"
              />
              <label class="small">Rufnummer</label>
              <b-form-invalid-feedback id="mobileNumberInputField-live-feedback">
                <span v-if="!$v.form.mobil_rufnummer.$model">Bitte geben Sie entweder Ihre Telefon- oder Mobiltelefon-Rufnummer an.</span>
                <span v-if="!$v.form.mobil_rufnummer.maxLength">Ihre Rufnummer darf maximal 50 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="small mb-2 mt-2">Telefax (Geschäft)/(Privat)</div>
          <div class="form-row">
            <div class="form-group col-md-3">
              <b-input
                  id="faxCountryPreselection"
                  type="text"
                  v-model.trim="$v.form.fax_laenderkennung.$model"
                  class="form-control form-control-sm form-control"
                  :state="validateState('fax_laenderkennung')"
                  aria-describedby="faxCountryPreselection-live-feedback"
              />
              <label class="small">Ländervorwahl</label>
              <b-form-invalid-feedback id="faxCountryPreselection-live-feedback">
                <span v-if="!$v.form.fax_laenderkennung.maxLength">Ihre Ländervorwahl darf maximal 10 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group col-md-3">
              <b-input
                  id="faxCityPreselection"
                  type="text"
                  v-model.trim="$v.form.fax_vorwahl.$model"
                  class="form-control form-control-sm form-control"
                  :state="validateState('fax_vorwahl')"
                  aria-describedby="faxCityPreselection-live-feedback"
              />
              <label class="small">Ortsvorwahl</label>
              <b-form-invalid-feedback id="faxCityPreselection-live-feedback">
                <span v-if="!$v.form.fax_vorwahl.maxLength">Ihre Ortsvorwahl darf maximal 10 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
            <div class="form-group col-md-6">
              <b-input
                  id="faxNumber"
                  type="text"
                  v-model.trim="$v.form.fax_rufnummer.$model"
                  class="form-control form-control-sm form-control"
                  :state="validateState('fax_rufnummer')"
                  aria-describedby="faxNumber-live-feedback"
              />
              <label class="small">Rufnummer</label>
              <b-form-invalid-feedback id="faxNumber-live-feedback">
                <span v-if="!$v.form.fax_rufnummer.maxLength">Ihre Rufnummer darf maximal 50 Zeichen betragen.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-form>
      </div>

      <div class="col-md-5 mb-4">
        <div class="grey-bg p-3">
          <h3>Alternative Postadresse</h3>
          <button v-if="this.isAlternativeAddress" type="button" @click="changeAlternativeAddress()" class="btn btn btn-default btn-form btn-primary mt-3 mb-5">Ändern</button>
          <button v-else type="button" @click="changeAlternativeAddress()" class="btn btn btn-default btn-form btn-primary mt-3 mb-5">Neu</button>

          <div v-if="!this.loadingSubsiteContent">
            <div v-html="this.hinweisAltPostadresse"></div>
          </div>

          <div v-if="!this.loadingSubsiteContent">
            <div v-html="this.hinweisErbInsolvenzfall"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="wizard-card-footer clearfix pl-0 pr-0">
      <div class="wizard-footer-left">
        <span role="button" tabindex="0">
          <button @click="goToPrevTab" class="btn btn btn-default btn-form btn-primary">Zurück</button>
        </span>
      </div>
      <div class="wizard-footer-right">
        <span role="button" tabindex="0">
          <button @click="goToNextTab" class="btn btn btn-default btn-form btn-primary">Weiter</button>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import AddressModal from "@/components/user/AddressModal";
import axios from "axios";
import {mapGetters} from "vuex";
import $ from 'jquery'

import {validationMixin} from 'vuelidate';
import {required, requiredUnless, maxLength} from "vuelidate/lib/validators";
import VueJwtDecode from "vue-jwt-decode";

export default {
  components: {AddressModal},
  data(){
    return {
      rawCountryData: [],
      countries: [],
      form: {
        companyName: '',
        salutation: '',
        titel: '',
        vorname: '',
        nachname: '',
        zusatz: '',
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
        country: 'DE',
        telefon_laenderkennung: '',
        telefon_vorwahl: '',
        telefon_rufnummer: '',
        mobil_laenderkennung: '',
        mobil_vorwahl: '',
        mobil_rufnummer: '',
        fax_laenderkennung: '',
        fax_vorwahl: '',
        fax_rufnummer: '',
      },
      addressData: {},

      hinweisAltPostadresse: '',
      hinweisErbInsolvenzfall: '',
      placeholders: ['%%username%%'],
      loadingSubsiteContent: false,
    }
  },
  validations() {
    return {
      form: {
        salutation: {
          required,
        },
        titel: {
          maxLength: maxLength(20),
        },
        companyName: {
          required,
          maxLength: maxLength(100),
        },
        vorname: {
          required,
          maxLength: maxLength(50),
        },
        nachname: {
          required,
          maxLength: maxLength(100),
        },
        strasse: {
          required,
          maxLength: maxLength(200),
        },
        hausnummer: {
          required,
          maxLength: maxLength(10),
        },
        zusatz: {
          maxLength: maxLength(200),
        },
        plz: {
          required,
          maxLength: maxLength(100),
        },
        ort: {
          required,
          maxLength: maxLength(200),
        },
        country: {
          required,
        },
        telefon_laenderkennung: {
          maxLength: maxLength(10),
        },
        telefon_vorwahl: {
          maxLength: maxLength(10),
        },
        telefon_rufnummer: {
          requiredIf: requiredUnless(function () {
            return (
                this.form.mobil_rufnummer && this.form.mobil_rufnummer !== ''
            )
          }),
          maxLength: maxLength(50),
        },
        mobil_laenderkennung: {
          maxLength: maxLength(10),
        },
        mobil_vorwahl: {
          maxLength: maxLength(10),
        },
        mobil_rufnummer: {
          requiredIf: requiredUnless(function () {
            return (
                this.form.telefon_rufnummer && this.form.telefon_rufnummer !== ''
            )
          }),
          maxLength: maxLength(50),
        },
        fax_laenderkennung: {
          maxLength: maxLength(10),
        },
        fax_vorwahl: {
          maxLength: maxLength(10),
        },
        fax_rufnummer: {
          maxLength: maxLength(50),
        },
      },
    }
  },
  computed: {
    ...mapGetters('auftragswizard', ['thirdStepData']),
    ...mapGetters('user', ['userProfileData']),
    isAlternativeAddress() {
      if(this.userProfileData.alternativeanschrift && 
          this.userProfileData.alternativeanschrift.strasse === '' &&
          this.userProfileData.alternativeanschrift.hausnummer === '' &&
          this.userProfileData.alternativeanschrift.zusatz === '' &&
          this.userProfileData.alternativeanschrift.plz === '' &&
          this.userProfileData.alternativeanschrift.land === '') {
        return false;
      } else {
        return true;
      }
    },
    isCompany() {
      if(this.userProfileData.typ === 'Firma') {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async transferFromStore() {
      var profileObj = this.getProfile();
      if (profileObj.typ === 'Firma') {
        this.form.companyName = profileObj.name;
        this.form.salutation = 'Herr';
        this.form.titel = 'x';
        this.form.vorname = 'x';
        this.form.nachname = 'x';
      } else {
        this.form.companyName = 'x';
        this.form.salutation = profileObj.anrede;
        this.form.titel = profileObj.titel;
        this.form.vorname = profileObj.vorname;
        this.form.nachname = profileObj.nachname;
      }
      if (profileObj.anschrift) {
        this.form.zusatz = profileObj.anschrift.zusatz;
        this.form.strasse = profileObj.anschrift.strasse;
        this.form.hausnummer = profileObj.anschrift.hausnummer;
        this.form.plz = profileObj.anschrift.plz;
        this.form.ort = profileObj.anschrift.ort;
        this.form.country = await this.mapCountryValue(profileObj.anschrift.land);
      }
    
      this.form.telefon_laenderkennung = profileObj.telefon_laenderkennung;
      this.form.telefon_vorwahl = profileObj.telefon_vorwahl;
      this.form.telefon_rufnummer = profileObj.telefon_rufnummer;
  
      this.form.mobil_laenderkennung = profileObj.mobil_laenderkennung;
      this.form.mobil_vorwahl = profileObj.mobil_vorwahl;
      this.form.mobil_rufnummer = profileObj.mobil_rufnummer;
      
      this.form.fax_laenderkennung = profileObj.fax_laenderkennung;
      this.form.fax_vorwahl = profileObj.fax_vorwahl;
      this.form.fax_rufnummer = profileObj.fax_rufnummer;
      
      this.disableInputs();
    },
    loadProfile() {
      this.$store.dispatch('user/loadUserProfile')
    },
    getProfile() {
      return this.userProfileData;
    },
    loadSubsiteContent() {
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      this.loadingSubsiteContent = true;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossdomain: true,
      };
      const uri = this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT + '/protected?contentTypes=32200,32210,32220,32230,32240';
      axios.get(uri, config)
          .then(response => {
            this.subsiteContent = response.data.content;
            this.loadingSubsiteContent = false;
            this.hinweisAltPostadresse = this.replacePlaceholders( this.getHtmlContent( 32230 ) );
            this.hinweisErbInsolvenzfall = this.replacePlaceholders( this.getHtmlContent( 32240 ) );
          } )
          .catch(error => {
            console.log( error );
            this.loadingSubsiteContent = true;
          })
    },
    replacePlaceholders( htmlContent ) {
      var replacedHtmlContent = htmlContent;
      var token = this.$store.getters['auth/getToken'];
      var decodedToken = {
        sub: '',
        fullname: '',
        email: '',
      }
      if ( token ) {
        decodedToken = VueJwtDecode.decode(token);
      }
      for( let placeholderIx in this.placeholders ) {
        if ( '%%username%%' === this.placeholders[placeholderIx] ) {
          replacedHtmlContent = replacedHtmlContent.replace( this.placeholders[placeholderIx], decodedToken.fullname )
        }
      }
      return replacedHtmlContent;
    },
    getHtmlContent( contentType ) {
      for ( let item in this.subsiteContent ) {
        var contentItem = this.subsiteContent[item];
        if ( contentType === contentItem.contentType.id ) {
          return contentItem.htmlcontent;
        }
      }
      return "No content for ID " + contentType;
    },
    async mapCountryKey( keySearch ) {
      if ( this.rawCountryData === undefined || this.rawCountryData.length === 0 ) {
        // DV-8665 this may not have been loaded yet
        console.log( "mapCountryKey - rawCountryData not yet loaded..." )
        await this.loadCountries();
        console.log( "mapCountryKey - rawCountryData now loaded..." )
      }
      console.log( "mapCountryKey - Searching in rawCountryData..." )
      for(let item in this.rawCountryData) {
        if ( this.rawCountryData[item].key === keySearch ) {
          return this.rawCountryData[item].value;
        }
      }
    },
    async mapCountryValue( valueSearch ) {
      
      if ( this.rawCountryData === undefined || this.rawCountryData.length === 0 ) {
        // DV-8665 this may not have been loaded yet
        console.log( "mapCountryValue - rawCountryData not yet loaded..." )
        await this.loadCountries();
        console.log( "mapCountryValue - rawCountryData now loaded..." )
      }
      console.log( "mapCountryValue - Searching in rawCountryData..." )
      for(let item in this.rawCountryData) {
        if ( this.rawCountryData[item].value === valueSearch ) {
          return this.rawCountryData[item].key;
        }
      }
    },
    loadCountries() {
      return axios.get( this.$store.getters.getAllEnvis.API_LAENDER )
          .then(response => {
            this.rawCountryData = response.data;
            this.countries = [];
            // Map the raw data key:value (DE:Deutschland) to a format understood by the b-select component value:text (DE:Deutschland)
            for(let item in this.rawCountryData) {
              this.countries.push( { value: this.rawCountryData[item].key, text: this.rawCountryData[item].value } )
            }
          } )
          .catch(error => {
            console.log(error);
            return [];
          })
    },
    changeAlternativeAddress () {
      this.addressData = this.userProfileData.alternativeanschrift;
      this.$bvModal.show( 'changeAddress' );
    },
    phoneNrCheck(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 43) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    saveThirdStepData() {

      const reqParams = {
        name: this.form.companyName,
        salutation: this.form.salutation,
        titel: this.form.titel,
        vorname: this.form.vorname,
        nachname: this.form.nachname,
        zusatz: this.form.zusatz,
        strasse: this.form.strasse,
        hausnummer: this.form.hausnummer,
        plz: this.form.plz,
        ort: this.form.ort,
        country: this.form.country,
        telefonLaenderkennung: this.form.telefon_laenderkennung,
        telefonVorwahl: this.form.telefon_vorwahl,
        telefonRufnummer: this.form.telefon_rufnummer,
        mobilLaenderkennung: this.form.mobil_laenderkennung,
        mobilVorwahl: this.form.mobil_vorwahl,
        mobilRufnummer: this.form.mobil_rufnummer,
        faxLaenderkennung: this.form.fax_laenderkennung,
        faxVorwahl: this.form.fax_vorwahl,
        faxRufnummer: this.form.fax_rufnummer,
      }
      this.$store.dispatch('auftragswizard/saveThirdStepForm',reqParams);
    },
    goToPrevTab() {
      this.saveThirdStepData();
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$emit('prevStep');
      }
      this.$refs.topAnchor.scrollIntoView({behavior: 'smooth'});
    },
    goToNextTab() {
      this.saveThirdStepData();
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$emit('nextStep');
      }
      this.$refs.topAnchor.scrollIntoView({behavior: 'smooth'});
    },
    disableNameInputs: function () {
      if (this.getProfile().typ === 'Firma' && this.getProfile().name) {
        $('#companyName').prop("disabled", "disabled");
      }
      if (this.getProfile().anrede) {
        $('#salutationInputField').prop("disabled", "disabled");
      }
      if (this.getProfile().titel) {
        $('#titel').prop("disabled", "disabled");
      }
      if (this.getProfile().vorname) {
        $('#vorname').prop("disabled", "disabled");
      }
      if (this.getProfile().nachname) {
        $('#nachname').prop("disabled", "disabled");
      }
    },
    disableAddressInputs: function () {
      if (this.getProfile().anschrift && this.getProfile().anschrift != null) {
        if (this.getProfile().anschrift.zusatz) {
          $('#zusatz').prop("disabled", "disabled");
        }
        if (this.getProfile().anschrift.strasse) {
          $('#strasse').prop("disabled", "disabled");
        }
        if (this.getProfile().anschrift.hausnummer) {
          $('#hausnummer').prop("disabled", "disabled");
        }
        if (this.getProfile().anschrift.plz) {
          $('#plz').prop("disabled", "disabled");
        }
        if (this.getProfile().anschrift.ort) {
          $('#ort').prop("disabled", "disabled");
        }

        // console.log("Anschrifts-Objekt: ", this.getProfile().anschrift);
        // console.log("Land: ", this.getProfile().anschrift.land);
        if (
            (this.getProfile().anschrift.land !== "") &&
            (this.getProfile().anschrift.land !== null) &&
            (this.getProfile().anschrift.land !== undefined) &&
            (this.getProfile().anschrift.land !== " ") &&
            (!/^\s+/.test(this.getProfile().anschrift.land))) {
          $('#countryInputField').prop("disabled", "disabled");
        }
      }
    },
    disableTelefoneInputs: function () {
      if (this.getProfile().telefon_laenderkennung) {
        $('#phoneCountryPreselection').prop("disabled", "disabled");
      }
      if (this.getProfile().telefon_vorwahl) {
        $('#phoneCityPreselection').prop("disabled", "disabled");
      }
      if (this.getProfile().telefon_rufnummer) {
        $('#phoneNumber').prop("disabled", "disabled");
      }

      if (this.getProfile().mobil_laenderkennung) {
        $('#mobileCountryPreselection').prop("disabled", "disabled");
      }
      if (this.getProfile().mobil_vorwahl) {
        $('#mobileCityPreselection').prop("disabled", "disabled");
      }
      if (this.getProfile().mobil_rufnummer) {
        $('#mobileNumber').prop("disabled", "disabled");
      }

      if (this.getProfile().fax_laenderkennung) {
        $('#faxCountryPreselection').prop("disabled", "disabled");
      }
      if (this.getProfile().fax_vorwahl) {
        $('#faxCityPreselection').prop("disabled", "disabled");
      }
      if (this.getProfile().fax_rufnummer) {
        $('#faxNumber').prop("disabled", "disabled");
      }
    },
    disableInputs() {
      this.disableNameInputs();
      this.disableAddressInputs();
      this.disableTelefoneInputs();
    }
  },
  watch: {
    thirdStepData: function () {
      /* Watch on 'thirdStepData' element to transfer local field values to wizard data object */
      if (this.thirdStepData) {
        this.form.companyName = this.thirdStepData.name;
        this.form.salutation = this.thirdStepData.salutation;
        this.form.titel = this.thirdStepData.titel;
        this.form.vorname = this.thirdStepData.vorname;
        this.form.nachname = this.thirdStepData.nachname;
        this.form.zusatz = this.thirdStepData.zusatz;
        this.form.strasse = this.thirdStepData.strasse;
        this.form.hausnummer = this.thirdStepData.hausnummer;
        this.form.plz = this.thirdStepData.plz;
        this.form.ort = this.thirdStepData.ort;
        this.form.country = this.thirdStepData.country;
        this.form.telefon_laenderkennung = this.thirdStepData.telefonLaenderkennung;
        this.form.telefon_vorwahl = this.thirdStepData.telefonVorwahl;
        this.form.telefon_rufnummer = this.thirdStepData.telefonRufnummer;
        this.form.mobil_laenderkennung = this.thirdStepData.mobilLaenderkennung;
        this.form.mobil_vorwahl = this.thirdStepData.mobilVorwahl;
        this.form.mobil_rufnummer = this.thirdStepData.mobilRufnummer;
        this.form.fax_laenderkennung = this.thirdStepData.faxLaenderkennung;
        this.form.fax_vorwahl = this.thirdStepData.faxVorwahl;
        this.form.fax_rufnummer = this.thirdStepData.faxRufnummer;
      }
    },
    userProfileData: function() {
      /* Watch on 'userProfileData' store element to transfer values to wizard data object */
      this.transferFromStore();
    }
  },
  mounted() {
    this.loadSubsiteContent();
  },
  created() {
    this.loadCountries();
    this.loadProfile();
    this.transferFromStore();
    this.disableInputs();
  },
  mixins: [validationMixin],
}
</script>

<style type="scss" scoped>
</style>
