
function validLimit(value) {

    if((value !== null) && (value !== undefined)) {
        let numberToString = value.toString();
        let formatNumber = numberToString.replace(",", ".");
        let afterPoint = formatNumber.split(".");

        if(afterPoint[1] !== undefined) {
            if((formatNumber >= 0.5) && (formatNumber <= 2500) && (afterPoint[1].length <= 1) && ((afterPoint[1] == 0) || (afterPoint[1] == 5) )) {
                return true;
            } else {
                return false;
            }
        } else {
            if((formatNumber >= 0.5) && (formatNumber <= 2500)) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return true;
    }
}

export default validLimit;
