<template>
  <div>

    <div v-if="this.fondsLoading">
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Daten werden geladen...</strong>
      </div>
    </div>

    <table v-else id="salesdetailtable" role="table" aria-busy="false" aria-colcount="6" class="table b-table table-bordered">
      <thead role="rowgroup" class="thead-dark">
        <tr role="row" class="bg-dark">
          <th role="columnheader" scope="col" class="col-25-percent" style="border-bottom: medium none;">
            <div class="text-left pointer pl-1 pt-2 pb-2">
              <span>
                Fondsname
              </span>
              <div class="fs-7 pt-1">Initiator</div>
            </div>
          </th>
          <th role="columnheader" scope="col" class="col-15-percent" style="border-bottom: medium none;">
            <div class="text-left pointer pl-1 pt-2 pb-2">
                <span>
                  Letzter Kurs
                </span>
              <div class="fs-7 pt-1">Datum</div>
            </div>
          </th>
          <th role="columnheader" scope="col" class="col-15-percent" style="border-bottom: medium none;">
            <div class="text-left pointer pl-1 pt-2 pb-2">
                <span>
                  Geld / Kauf
                </span>
              <div class="fs-7 pt-1">Nominalbeteiligung</div>
            </div>
          </th>
          <th role="columnheader" scope="col" class="col-15-percent" style="border-bottom: medium none;">
            <div class="text-left pointer pl-1 pt-2 pb-2">
                <span>
                  Brief / Verkauf
                </span>
              <div class="fs-7 pt-1">Nominalbeteiligung</div>
            </div>
          </th>
          <th role="columnheader" scope="col" class="col-15-percent" style="border-bottom: medium none;">
            <div class="text-left pointer pl-1 pt-2 pb-2">
              <span>
                Umsatz 12 Monate
              </span>
              <div class="fs-7 pt-1">Hoch/Tief 12 Monate</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody role="rowgroup">
      <tr role="row" aria-rowindex="1">
        <td aria-colindex="2" role="cell" class="">
          <div class="text-left">
            <strong>
              <router-link :to="detailsUrl(`${this.fondsdata.id}`)">{{this.fondsdata.fondsname}}</router-link>
            </strong>
          </div>
          <div class="text-left fs-7">
            {{this.fondsdata.anbietername}}
          </div>
        </td>
        <td aria-colindex="3" role="cell" class="">
          <div>
            <div class="text-left">
              {{ formatPercent( this.fondsdata.lastkurs ) }}<span v-if="this.fondsdata.lastkurs">%</span><span v-else>&nbsp;</span>
            </div>
            <div class="text-left fs-7">
                <span>
                  {{this.fondsdata.lastkursdate}}
                </span>
            </div>
          </div>
        </td>
        <td aria-colindex="4" role="cell">
          <div>
            <div class="text-left">
              {{ formatPercent( this.fondsdata.buyrate ) }}<span v-if="this.fondsdata.buyrate">%</span><span v-else>&nbsp;</span>
            </div>
            <div class="text-left fs-7">
                <span>
                  <span v-if="this.fondsdata.buyvolume">{{this.fondsdata.currency}} {{ formatPrice( this.fondsdata.buyvolume ) }}</span>
                </span>
            </div>
          </div>
        </td>
        <td aria-colindex="5" role="cell">
          <div>
            <div class="text-left">
              {{ formatPercent( this.fondsdata.sellrate ) }}<span v-if="this.fondsdata.sellrate">%</span><span v-else>&nbsp;</span>
            </div>
            <div class="text-left fs-7">
                <span>
                  <span v-if="this.fondsdata.sellvolume">{{this.fondsdata.currency}} {{ formatPrice( this.fondsdata.sellvolume ) }}</span>
                </span>
            </div>
          </div>
        </td>
        <td aria-colindex="6" role="cell" class="align-content-center">
          <div>
            <div class="text-left">
              <span v-if="this.fondsdata.totalrevenue">{{this.fondsdata.currency}} {{ formatPrice( this.fondsdata.totalrevenue ) }}</span>
            </div>
            <div class="text-left fs-7">
                <span>
                  <span v-if="this.fondsdata.highrate">{{ formatPercent( this.fondsdata.highrate ) }}% / {{ formatPercent( this.fondsdata.lowrate ) }}%</span>
                </span>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import {mapActions, mapGetters} from "vuex";

export default {
  methods: {
    ...mapActions('auftragswizard', ['fetchFondsData']),

    detailsUrl( tid ) {
      return "fondsdetails/" + tid;
    },
  },
  computed: {
    ...mapGetters('auftragswizard', ['fondsdata', 'fondsLoading']),
  },
  mixins: [priceMixin,percentMixin],
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('tid');
    this.fetchFondsData( id );
  },
}
</script>

<style lang="scss">

</style>