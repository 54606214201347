<template>
  <div class="mobileTable">

    <div v-if="this.fondsLoading">
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Daten werden geladen...</strong>
      </div>
    </div>

    <table v-else id="salesdetailtableMobile" show-header="true" role="table" aria-busy="false" aria-colcount="7" class="table b-table table-bordered b-table-stacked">
      <tbody role="rowgroup">
        <tr role="row" aria-rowindex="1">
          <td aria-colindex="1" data-label="Anbieter" role="cell" class="mobileRow">
            <div>
              <table class="innerTable">
                <tr>
                  <td class="text-left label1">
                    Fonds<br>
                    <div>
                      Anbieter
                    </div>
                  </td>
                  <td class="label2">
                    <strong>
                      <router-link :to="detailsUrl(`${this.fondsdata.id}`)">{{this.fondsdata.fondsname}}</router-link>
                    </strong>
                    <br>
                    <span>
                      {{this.fondsdata.anbietername}}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td aria-colindex="2" data-label="Datum" role="cell" class="mobileRow">
            <div>
              <table class="innerTable">
                <tr>
                  <td class="text-left label1">
                    Letzter Kurs<br>
                    <div>
                      Datum
                    </div>
                  </td>
                  <td class="label2">
                    {{ formatPercent( this.fondsdata.lastkurs ) }}<span v-if="this.fondsdata.lastkurs">%</span><span v-else>&nbsp;</span><br>
                    <span class="fs-7">
                      {{this.fondsdata.lastkursdate}}
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td aria-colindex="3" data-label="Volumen" role="cell" class="mobileRow">
            <div>
              <div>
                <table class="innerTable">
                  <tr>
                    <td class="text-left label1">
                      Geld / Kauf<br>
                      <div>
                        Nominalbeteiligung
                      </div>
                    </td>
                    <td class="label2">
                      {{ formatPercent( this.fondsdata.buyrate ) }}<span v-if="this.fondsdata.buyrate">%</span><span v-else>&nbsp;</span><br>
                      <span class="fs-7">
                        <span v-if="this.fondsdata.buyvolume">{{this.fondsdata.currency}} {{ formatPrice( this.fondsdata.buyvolume ) }}</span>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </td>
          <td aria-colindex="4" data-label="Volumen" role="cell" class="mobileRow">
            <div>
              <div>
                <table class="innerTable">
                  <tr><td class="text-left label1">
                    Brief / Verkauf<br>
                    <div>
                      Nominalbeteiligung
                    </div>
                  </td>
                    <td class="label2">
                      {{ formatPercent( this.fondsdata.sellrate ) }}<span v-if="this.fondsdata.sellrate">%</span><span v-else>&nbsp;</span><br>
                      <span class="fs-7">
                        <span v-if="this.fondsdata.sellvolume">{{this.fondsdata.currency}} {{ formatPrice( this.fondsdata.sellvolume ) }}</span>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </td>
          <td aria-colindex="5" data-label="Hoch / Tief 12 Monate" role="cell" class="mobileRow">
            <div>
              <table class="innerTable">
                <tr>
                  <td class="text-left label1">
                    Umsatz 12 Monate<br>
                    <div>
                      Hoch / Tief 12 Monate
                    </div>
                  </td>
                  <td class="label2">
                    <span>
                      <span v-if="this.fondsdata.totalrevenue">{{this.fondsdata.currency}} {{ formatPrice( this.fondsdata.totalrevenue ) }}</span>
                    </span>
                    <br>
                    <span class="fs-7">
                      <span v-if="this.fondsdata.highrate">{{ formatPercent( this.fondsdata.highrate ) }}% / {{ formatPercent( this.fondsdata.lowrate ) }}%</span>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td aria-colindex="7" data-label="" role="cell" class="mobileRow">
            <div>
              <table class="innerTable">
                <tr>
                  <td>
                    &nbsp;
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import {mapActions, mapGetters} from "vuex";

export default {
  methods: {
    ...mapActions('auftragswizard', ['fetchFondsData']),

    detailsUrl( tid ) {
      return "fondsdetails/" + tid;
    },
  },
  computed: {
    ...mapGetters('auftragswizard', ['fondsdata', 'fondsLoading']),
  },
  mixins: [priceMixin,percentMixin],
  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('tid');
    this.fetchFondsData( id );
  },
}
</script>

<style lang="scss">

</style>