import moment from "moment";

function validValidUntil(value) {

    let todaysDate = new Date();

    let splitDate = value.split('.');
    let year = splitDate[2];
    let month = splitDate[1];
    let day = splitDate[0];

    let startDateObj = year + '-' + month + '-' + day
    let formattedStartDateObj = new Date(startDateObj);

    let maxFutureDate = moment(todaysDate).add(9, 'months').add(1, 'days')

    if(formattedStartDateObj instanceof Date && !isNaN(formattedStartDateObj)) {
        if(((moment(formattedStartDateObj).isSame(todaysDate, "day")) || (moment(formattedStartDateObj).isSameOrAfter(todaysDate))) && (moment(formattedStartDateObj).isBefore(maxFutureDate, 'day'))) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export default validValidUntil;
