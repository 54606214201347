<template>
  <div>
    <form ref="topAnchor"></form>

    <div v-if="this.loadingSubsiteContent" class="text-center my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">Die Daten werden geladen...</strong>
    </div>

    <div v-if="!this.loadingSubsiteContent && !this.isAuftragChange">
      <div v-html="this.kopfbereichAuftragAnlegen"></div>
    </div>

    <div v-if="!this.loadingSubsiteContent && this.isAuftragChange">
      <div v-html="this.kopfbereichAuftragAendern"></div>
    </div>

    <div v-if="!this.loadingSubsiteContent">
      <div v-html="this.auftragshinweis"></div>
    </div>

    <div class="wizard-card-footer clearfix pl-0 pr-0">
      <div class="wizard-footer-left">

      </div>
      <div class="wizard-footer-right">
        <span role="button" tabindex="0">
          <button @click="goToNextTab" class="btn btn btn-default btn-form btn-primary">Weiter</button>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: "FirstStep",
  data() {
    return {
      loadingSubsiteContent: false,
      subsiteContent: [],

      kopfbereichAuftragAnlegen: '',
      kopfbereichAuftragAendern: '',
      auftragshinweis: '',

      validationLoadingState: false,
      placeholders: ['%%username%%'],
    }
  },
  methods: {
    loadSubsiteContent() {
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      this.loadingSubsiteContent = true;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        crossdomain: true,
      };
      const uri = this.$store.getters.getAllEnvis.API_SUBSITE_CONTENT + '/protected?contentTypes=32200,32210,32220,32230,32240';
      axios.get(uri, config)
          .then(response => {
            this.subsiteContent = response.data.content;
            this.loadingSubsiteContent = false;
            this.kopfbereichAuftragAnlegen = this.replacePlaceholders( this.getHtmlContent( 32200 ) );
            this.kopfbereichAuftragAendern = this.replacePlaceholders( this.getHtmlContent( 32210 ) );
            this.auftragshinweis = this.replacePlaceholders( this.getHtmlContent( 32220 ) );
          } )
          .catch(error => {
            console.log( error );
            this.loadingSubsiteContent = true;
          })
    },
    replacePlaceholders( htmlContent ) {
      var replacedHtmlContent = htmlContent;
      var token = this.$store.getters['auth/getToken'];
      var decodedToken = {
        sub: '',
        fullname: '',
        email: '',
      }
      if ( token ) {
        decodedToken = VueJwtDecode.decode(token);
      }
      for( let placeholderIx in this.placeholders ) {
        if ( '%%username%%' === this.placeholders[placeholderIx] ) {
          replacedHtmlContent = replacedHtmlContent.replace( this.placeholders[placeholderIx], decodedToken.fullname )
        }
      }
      return replacedHtmlContent;
    },
    getHtmlContent( contentType ) {
      for ( let item in this.subsiteContent ) {
        var contentItem = this.subsiteContent[item];
        if ( contentType === contentItem.contentType.id ) {
          return contentItem.htmlcontent;
        }
      }
      return "No content for ID " + contentType;
    },
    getAuftragsTyp() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get('auftragType');

      this.$store.dispatch('auftragswizard/saveAuftragsTyp',type);
    },
    goToNextTab() {
      this.$emit('nextStep');
      this.$refs.topAnchor.scrollIntoView({behavior: 'smooth'});
    },
  },
  computed: {
    ...mapGetters('auftragswizard', ['auftragsTyp']),
    isAuftragChange() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const auftragNr = urlParams.get('auftragNr');
      if(auftragNr) {
        return true
      } else {
        return false
      }
    }
  },
  mounted() {
    this.loadSubsiteContent();
  },
  created() {
    this.$store.dispatch('auftragswizard/emptyFondsData');
    this.getAuftragsTyp();
  },
}
</script>

<style type="scss" scoped>
</style>