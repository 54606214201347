
function validNominale(value) {

    if((value !== null) && (value !== undefined)) {
        let numberToString = value.toString();
        let formatNumber = numberToString.replace(",", ".");
        let afterPoint = formatNumber.split(".");

        if(afterPoint[1] !== undefined) {
            if((formatNumber >= 0.5) && (formatNumber <= 1000000000) && (afterPoint[1].length <= 2)) {
                return true;
            } else {
                return false;
            }
        } else {
            if((formatNumber >= 0.5) && (formatNumber <= 1000000000)) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return true;
    }
}

export default validNominale;
