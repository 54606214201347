<template>
  <div>
    <form ref="topAnchor"></form>
    <SalesDetailTableMobile v-if="mobileViewport"></SalesDetailTableMobile>
    <SalesDetailTable v-else></SalesDetailTable>

    <div class="row justify-content-around mt-4">
      <div class="col-md-6 mb-4">
        <p>
          Handelswährung: <strong>{{this.fondsdata.currency}}</strong>
        </p>
        <b-form id="dataForm"
              ref="form"
              @change="saveSecondStepData();"
              v-model="form.valid"
        >
          <div class="form-row">
            <div class="form-group col-md-12 required">
              <label class="small">
                <span>
                  Limit
                  <i id="limitInputField-label-hint" class="fas fa-question-circle"></i>
                </span>
              </label>
              <b-input
                  v-model.trim="$v.form.limit.$model"
                  type="text"
                  v-on:keypress="NumbersOnly"
                  :state="validateState('limit')"
                  class="form-control form-control-sm"
                  area-descibed-by="limitInputField-live-feedback" />
              <b-form-invalid-feedback id="limitInputField-live-feedback">
                <span v-if="!$v.form.limit.$model">Bitte geben Sie ein Limit an.</span>
                <span v-else-if="!$v.form.limit.limitOk === true">Bitte geben Sie ein Limit zwischen 0,5 und 2500 mit max. einer Dezimalstelle hinter dem Komma ein.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 required">
              <label class="small">
                <span>
                  Beauftr. Nominale
                  <i id="nominalInputField-label-hint" class="fas fa-question-circle"></i>
                </span>
              </label>
              <b-input
                  v-model.trim="$v.form.nominale.$model"
                  type="text"
                  v-on:keypress="NumbersOnly"
                  :state="validateState('nominale')"
                  class="form-control form-control-sm"
                  aria-describedby="nominaleInputField-live-feedback" />
              <b-form-invalid-feedback id="nominaleInputField-live-feedback">
                <span v-if="!$v.form.nominale.$model">Bitte geben Sie die beauftragte Nominale an.</span>
                <span v-else-if="!$v.form.nominale.nominaleOk === true">Bitte geben Sie eine beauftragte Nominale zwischen 0,5 und 1000000000 mit max. 2 Dezimalstellen hinter dem Komma ein.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group calendar col-md-12 required">
              <label class="small">
                <span>
                  Laufzeit
                  <i id="validUntilInputField-label-hint" class="fas fa-question-circle"></i>
                </span>
              </label>
              <i class="fas fa-calendar-day"></i>
              <datepicker
                  v-model.trim="$v.form.validUntil.$model"
                  aria-describedby="validUntilInputField-live-feedback"
                  :format="'dd.MM.yyyy'"
                  @input="addValidationClasses();dateToString()"
              ></datepicker>
              <b-input
                  id="hiddenDateInput"
                  v-model="form.validUntilString"
                  hidden
                  :state="validateState('validUntil')"
                  class="form-control form-control-sm"
                   />
              <b-form-invalid-feedback id="validUntilInputField-live-feedback">
                <span v-if="!$v.form.validUntil.$model">Bitte geben Sie eine Laufzeit an.</span>
                <span v-else-if="!$v.form.validUntil.validUntilOk === true">Bitte geben Sie ein gültiges Datum zwischen heute und in 9 Monaten an.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 required">
              <label class="small">
                <span>
                  Teilausführung
                  <i id="partialBrokerageInputField-label-hint" class="fas fa-question-circle"></i>
                </span>
              </label>
              <b-form-radio-group
                  v-model.trim="$v.form.partialBrokerage.$model"
                  :state="validateState('partialBrokerage')"
                  aria-describedby="partialBrokerageInputField-live-feedback"
                  class="mb-2"
                  name="teilausfuehrung"
                  @change="getBrokerageState"
              >
                <b-form-radio value="Ja">
                  Ja
                </b-form-radio>
                <b-form-radio value="Nein">
                  Nein
                </b-form-radio>
              </b-form-radio-group>
              <b-form-invalid-feedback id="partialBrokerageInputField-live-feedback">
                <span>Bitte geben die Teilausführung an.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="small">
                <span>
                  Wunschstückelung
                  <i id="divisionInputField-label-hint" class="fas fa-question-circle"></i>
                </span>
              </label>
              <b-input
                  v-model.trim="$v.form.division.$model"
                  :state="validateState('division')"
                  type="text"
                  v-on:keypress="NumbersOnly"
                  aria-describedby="divisionInputField-live-feedback"
                  class="form-control form-control-sm"
                  :disabled="this.form.partialBrokerage == 'Nein'"/>
              <b-form-invalid-feedback id="divisionInputField-live-feedback">
                <span>Bitte geben Sie eine Stückelung zwischen 0,5 und 1000000000 mit max. 2 Dezimalstellen hinter dem Komma ein.</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <b-tooltip v-if="hints.hintLimit.content" target="limitInputField-label-hint" triggers="hover" placement="topright">
            <span v-html="hints.hintLimit.content"></span>
          </b-tooltip>
          <b-tooltip v-if="hints.hintNominal.content" target="nominalInputField-label-hint" triggers="hover" placement="topright">
            <span v-html="hints.hintNominal.content"></span>
          </b-tooltip>
          <b-tooltip v-if="hints.hintValidUntil.content" target="validUntilInputField-label-hint" triggers="hover" placement="topright">
            <span v-html="hints.hintValidUntil.content"></span>
          </b-tooltip>
          <b-tooltip v-if="hints.hintPartialBrokerage.content" target="partialBrokerageInputField-label-hint" triggers="hover" placement="topright">
            <span v-html="hints.hintPartialBrokerage.content"></span>
          </b-tooltip>
          <b-tooltip v-if="hints.hintDivision.content" target="divisionInputField-label-hint" triggers="hover" placement="topright">
            <span v-html="hints.hintDivision.content"></span>
          </b-tooltip>
        </b-form>
        <p class="mt-3">
          <small>Die mit einem * gekennzeichneten Felder sind Pflichtangaben.</small>
        </p>
      </div>

      <div class="col-md-6 mb-4 mt-6">
        <div class="grey-bg p-3" v-if="this.hints.uebertragungshinweise">
          <h3>Wichtige Information:</h3>
          <p>
            {{this.hints.uebertragungshinweise}}
          </p>
        </div>
      </div>
    </div>

    <div class="wizard-card-footer clearfix pl-0 pr-0">
      <div class="wizard-footer-left">
        <span role="button" tabindex="0">
          <button @click="goToPrevTab" class="btn btn btn-default btn-form btn-primary">Zurück</button>
        </span>
      </div>
      <div class="wizard-footer-right">
        <span role="button" tabindex="0">
          <button @click="goToNextTab" class="btn btn btn-default btn-form btn-primary">Weiter</button>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import SalesDetailTableMobile from "@/components/auftragswizard/salesdetail/SalesDetailTableMobile";
import SalesDetailTable from "@/components/auftragswizard/salesdetail/SalesDetailTable";
import Datepicker from 'vuejs-datepicker';
import axios from "axios";
import {mapGetters} from "vuex";
import moment from "moment";
import $ from "jquery";

import {validationMixin} from 'vuelidate';
import {required} from "vuelidate/lib/validators";


import validLimit from "@/mixins/wizardValidation/validLimit";
import validNominale from "@/mixins/wizardValidation/validNominale";
import validValidUntil from "@/mixins/wizardValidation/validValidUntil";
import validDivision from "@/mixins/wizardValidation/validDivision";


const limitOk = (value) => {
  return validLimit(value);
}

const nominaleOk = (value) => {
  return validNominale(value);
}

const validUntilOk = (value) => {
  let editedDate = moment(String(value)).format('DD.MM.YYYY');
  return validValidUntil(editedDate);
}

const divisionOk = (value) => {
  return validDivision(value);
}


export default {
  components: {Datepicker, SalesDetailTable, SalesDetailTableMobile},
  data(){
    return {
      hints: {
        uebertragungshinweise: '',
        hintLimit: {
          content: '',
        },
        hintNominal: {
          content: '',
        },
        hintValidUntil: {
          content: '',
        },
        hintPartialBrokerage: {
          content: '',
        },
        hintDivision: {
          content: '',
        },
      },
      form: {
        limit: '',
        nominale: '',
        validUntilString: '',
        validUntil: '',
        partialBrokerage: 'Nein',
        division: '',
        valid: true,
      }
    }
  },
  validations() {
    return {
      form: {
        limit: {
          required,
          limitOk,
        },
        nominale: {
          required,
          nominaleOk,
        },
        validUntil: {
          required,
          validUntilOk,
        },
        partialBrokerage: {
          required,
        },
        division: {
          divisionOk,
        },
      },
    }
  },
  computed: {
    ...mapGetters('auftragswizard', ['fondsdata', 'orderData', 'auftragsTyp', 'secondStepData', 'thirdStepData']),
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    }
  },
  methods: {
    loadOrderData() {

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get('id');

      if((id !== '') && ((id !== null))) {
        this.$store.dispatch('auftragswizard/fetchOrderData',id);
      }
    },
    loadHints() {
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          'XClacksOverhead': 'GNU Terry Pratchett',
          'X-API-KEY': this.$store.getters.getAllEnvis.API_KEY,
        },
        crossdomain: true,
      };

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get('tid');
      const type = urlParams.get('auftragType');

      const uri = this.$store.getters.getAllEnvis.API_TRANCHE_HANDELSINFORMATIONEN.replace("{id}", id) + '?auftragtyp=' + type + '&subsite=' + this.$store.getters["getSubsite"];
      axios.get(uri, config)
          .then(response => {
            this.hints = response.data;
          })
          .catch(error => {
            console.log( error );
          })
    },
    loadProfile() {
      this.$store.dispatch('user/loadUserProfile')
    },
    dateToString() {
      let d = new Date(this.form.validUntil);
      this.form.validUntilString = d.getUTCDate() +"."+ (d.getUTCMonth()+1) +"."+ d.getUTCFullYear();
    },
    preSetValidUntil() {
      if(this.validUntil !== null) {
        let todaysDate = new Date();
        if (this.auftragsTyp === 'KAUF') {
          let tomorrowDate = moment(todaysDate).add(1, 'days');
          if (todaysDate.getHours() >= 14) {
            tomorrowDate = tomorrowDate.startOf('day');
            if (tomorrowDate.weekday() === 6) { // 6 = Samstag
              tomorrowDate = tomorrowDate.add(2, 'days'); // Samstag -> Montag
            } else if (tomorrowDate.weekday() === 0) { // 0 = Sonntag
              tomorrowDate = tomorrowDate.add(1, 'days'); // Sonntag -> Montag
            }
            this.form.validUntil = tomorrowDate.toDate();
          } else {
            this.form.validUntil = todaysDate;
          }
        } else if (this.auftragsTyp === 'VERKAUF') {
          this.form.validUntil = moment(todaysDate).add(9, 'months').toDate();
        } else {
          this.form.validUntil = null;
        }
      }
    },
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    saveSecondStepData() {
      let newLimit = this.form.limit.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
      let newNominale = this.form.nominale.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      let newDivision = this.form.division.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      const reqParams = {
        limit: newLimit,
        nominale: newNominale,
        validUntil: this.form.validUntil,
        partialBrokerage: this.form.partialBrokerage,
        division: newDivision,
      }
      this.$store.dispatch('auftragswizard/saveSecondStepForm',reqParams);
    },
    addValidationClasses() {
      let datePicker = $('.vdp-datepicker input');
      datePicker.addClass('form-control')

      if (this.$v.form.validUntil.validUntilOk === true){
        datePicker.removeClass('is-invalid')
        datePicker.addClass('is-valid')
      } else if(this.$v.form.validUntil.validUntilOk === false) {
        datePicker.removeClass('is-valid')
        datePicker.addClass('is-invalid')
      }
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getBrokerageState() {
      if(this.form.partialBrokerage === 'Nein') {
        this.form.division = '';
      }
    },
    goToPrevTab() {
      this.saveSecondStepData();
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('prevStep');
      }
      this.$refs.topAnchor.scrollIntoView({behavior: 'smooth'});
    },
    goToNextTab() {
      this.saveSecondStepData();
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('nextStep');
        if(!this.thirdStepData) {
          this.loadProfile();
        }
      }
      this.$refs.topAnchor.scrollIntoView({behavior: 'smooth'});
    },
  },
  watch: {
    secondStepData: function () {
      if (this.secondStepData) {
        this.form.limit = this.secondStepData.limit;
        this.form.nominale = this.secondStepData.nominale;
        this.form.validUntil = this.secondStepData.validUntil;
        this.form.partialBrokerage = this.secondStepData.partialBrokerage;
        this.form.division = this.secondStepData.division;
      }
    },
    orderData: function () {
      if (this.orderData) {

        let limitString = this.orderData.limit;
        let formattedLimit = limitString.toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
        this.form.limit = formattedLimit.split('.').join('');

        let beauftragteNominaleString = this.orderData.beauftragteNominale.betrag;
        let formattedBeauftragteNominale = beauftragteNominaleString.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        this.form.nominale = formattedBeauftragteNominale.split('.').join('');

        let splitDate = this.orderData.ablauf.split('.');
        let newFormattedDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
        this.form.validUntil = newFormattedDate;

        if(this.orderData.teilausfuehrbar === true) {
          this.form.partialBrokerage = 'Ja';
        } else {
          this.form.partialBrokerage = 'Nein';
        }

        if(this.orderData.stueckelung) {
          let divisionString = this.orderData.stueckelung.betrag;
          let formattedDivision = divisionString.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          this.form.division = formattedDivision.split('.').join('');
        }
      }
    }
  },
  created() {
    this.loadOrderData();
    this.loadHints();
    this.preSetValidUntil();
  },
  mixins: [validationMixin],
}
</script>

<style type="scss" scoped>
</style>